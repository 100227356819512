import React, { useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Routes, Route, Link } from "react-router-dom";
import _ from "lodash";

export default function Report(props) {
  function getMatchingEvals(id) {
    return props.evals.filter((e) => e.StudentID === id);
  }

  const [show, setShow] = useState(true)
  const [showID, setShowID] = useState(false)

  function toggle(){
    setShow(!show);
  }

  function toggleID(){
    setShowID(!showID);
  }

  function avgScore(evals) {
    if (evals.length < 2) return "";
    let result = "";
    result = (totalScore(evals[0]) + totalScore(evals[1])) / 2;
    if (result) return result;
    else return -1;
  }

  function totalScore(e) {
    let result = "";
    if (!e) return result;
    else
      result =
        e.OverallScore.Value +
        e.BonusScore.Value +
        _.sum(e.Rubric.map((x) => x.Value));
    if (result < 0) result = "Not eligible";
    return result;
  }

  function isEligible(a) {
    return a.Race.some((r) =>
      [
        "American Indian or Alaska Native",
        "Black or African American",
        "Native Hawaiian or Pacific Islander",
        "Other",
      ].includes(r)
    );
  }

  return (
    <div className="my-5">
      <h2 style={{ background: "lightgrey" }} className="p-2">
        Report: Application Rankings
        <Button className="" style={{float:"right"}} onClick={toggle}>{!show ? 'Show' : 'Hide'}</Button>
      </h2>

      <Button onClick={toggleID }>Identifying Info</Button>
      {show && <Table striped bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>Applicant #</th>
            {showID && <th>Info</th>}
            <th>Score 1</th>
            <th>Score 2</th>
            <th>Median Score</th>
            <th>Ed Status</th>
            <th>Citizenship</th>
            <th>Disc</th>
            {/* <th><Form.Control as="select">{Object.getOwnPropertyNames(props.apps[0])}</Form.Control></th> */}
            <th>App Details</th>
          </tr>
        </thead>
        <tbody>
          {[...props.apps]
            .filter((a) => isEligible(a))
            .sort((a, b) => {
              var scoreA = avgScore(getMatchingEvals(a._id));
              var scoreB = avgScore(getMatchingEvals(b._id));
              return scoreA > scoreB ? -1 : 1;
            })
            .map((a, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{props.apps.indexOf(a) + 1}</td>
                {/* <td>{a.Race.join(",")}</td> */}
                { showID && <td>{a.FirstName + " " + a.LastName + " " + a.Email}</td>}
                <td>{totalScore(getMatchingEvals(a._id)[0])} </td>
                <td>{totalScore(getMatchingEvals(a._id)[1])} </td>
                <td>
                  {avgScore(getMatchingEvals(a._id)) >= 0
                    ? avgScore(getMatchingEvals(a._id))
                    : "NE"}
                </td>
                <td>{a.Current_education_status}</td>
                <td>{a.Current_citizenship_status}</td>
                <td>{a.DiscoveryMode}</td>
                <td>
                  <Link to={`${a._id}`}>
                    <Button>View</Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>}
    </div>
  );
}
