import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// specify upload params and url for your files
function getUploadParams({ meta }) {
  return { url: "https://httpbin.org/post" };
}

export default function W2upload() {
  const [file, setFile] = useState(null);
  const [fileUploadProcessing, setFileUploadProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
    setFileUploadProcessing(true);
    if (status === "done") {
      // this.props.values.files.push({ imageFile: file, metadata: meta });
      setFile({ imageFile: file, metadata: meta });
      // this.setState({ fileUploadProcessing: false });
      setFileUploadProcessing(false);
    }
    if (status === "removed") {
      setFile({ imageFile: null, metadata: null });
      setFileUploadProcessing(false);
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = () => {
    var id = uuidv4();
    var obj = {
      file: file.imageFile,
      type: file.metadata.type,
      name: file.metadata.name,
      id: id,
    };
    // var s3URL = "";
    const imageData = new FormData();
    imageData.append("image", obj.file);
    imageData.append("type", obj.type);
    imageData.append("name", obj.name);
    axios
      .post("/students/addW2File", imageData)
      .then((res) => {
        // s3URL = res.data;
        console.log(res.data);
        setSubmitted(true);
      })
      .catch((err) => {
        // this.setState({ error: err });
        
        console.log(err);
      });
  };

  return (
    <div>
      <Row className="justify-content-center">
        {!submitted ? (
          <Col md={6}>
            <div className="my-5">
              <h2 className="font-weight-bold">W-9 Upload</h2>

              <p>Please use the area below to upload your valid W-9 form.</p>
            </div>

            <Form.Row>
              <Form.Group as={Col}>
                <p className="mt-0">
                  Accepted file formats: .png, .jpeg, .pdf{" "}
                  <i>(Each file must be 5MB or less)</i>
                </p>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  maxFiles={1}
                  maxSizeBytes={5000000}
                />
                <Button className="my-5" onClick={handleSubmit} disabled={!file}>
                  Submit
                </Button>
              </Form.Group>
            </Form.Row>
          </Col>
        ) : (
          <Col md={6}>
            <div className="mt-20" style={{height:'100vh'}}>
              <h2>Your W-9 form has been successfully uploaded</h2>

              <p>You may now close this window.</p>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
