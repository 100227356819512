import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  Button,
} from "react-bootstrap";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidCredentials: false,
    };
  }

  render() {
    return (
      <div>
        <Row className="justify-content-center">
          <Col md={6}>
            <h1>Admin Sign-in</h1>
            <Form>
              <Form.Row id="sign-in-dialog" className="">
                <Form.Group as={Col}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    required
                    name="email"
                    aria-label="email"
                    aria-describedby="email"
                    value={this.props.email}
                    onChange={this.props.onChange.handleChange}
                  />
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    autoComplete="on"
                    required
                    name="password"
                    aria-label="password"
                    aria-describedby="password"
                    value={this.props.password}
                    onChange={this.props.onChange.handleChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") this.props.onChange.signIn();
                    }}
                  />
                  <Button
                    className="mt-2"
                    variant="outline-primary"
                    disabled={!this.props.email || !this.props.password}
                    onClick={this.props.onChange.signIn}
                  >
                    Sign-in
                  </Button>
                  {this.state.invalidCredentials && (
                    <div className="text-danger mt-1">Invalid Credentials</div>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
