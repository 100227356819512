//This page is the last page of the scholarship form
//It contains fields for leadership, extracurricular activities, etc.
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Col, Row, Button, InputGroup, Table } from "react-bootstrap";
import List from "./list.component";
import "./page4.css";
import { BsTrash} from "react-icons/bs";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

export default class Page4 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.wordCount = this.wordCount.bind(this);
    this.charCount = this.charCount.bind(this);
    this.overCount = this.overCount.bind(this);
    this.isCourseworkFieldsetComplete = this.isCourseworkFieldsetComplete.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this);
    this.resetCoursework = this.resetCoursework.bind(this)
    this.state = {
      courseworkDate: "",
      courseworkDateBack: "",
      courseworkTitle: "",
      courseworkGrade: "",
    };

    this.ReactTags = React.createRef();
  }

  componentDidMount() {}

  handleChange(e) {
    // console.log(e.target.value);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  wordCount() {
    return this.props.values.notes.split(/\s+\b/).length || 0;
  }
  charCount() {
    return this.props.values.notes.length;
  }
  overCount() {
    return this.wordCount() > 150 || this.charCount() > 1500;
  }

  handleDateChange(e) {
    if (Date.parse(e.target.value)) {
      var doo = new Date(e.target.value);
      var date = new Date(
        doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
      );
      this.setState({ courseworkDateBack: date, courseworkDate: e.target.value });
    }
  }

  resetCoursework(){
    this.setState({courseworkTitle: "", courseworkDate: "", courseworkGrade: "", courseworkDateBack: ""})
  }

  isCourseworkFieldsetComplete(){
    return this.state.courseworkDate.length > 0 && this.state.courseworkGrade.length > 0 && this.state.courseworkTitle.length > 0
  }

  render() {
    if (this.state.redirect) {
      return <Link to={this.state.redirect} />;
    }
    return (
      <Row className="justify-content-center">
        <Col md={6}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h6 className="font-weight-bold">Additional Experiences</h6>
            <em className="mb-5">
              The following fields are optional, but will be considered when
              reviewing your application. Therefore, we strongly encourage you
              to provide all information you feel is relevant.
            </em>

            <List
              name="awards"
              value={this.props.values.awards}
              onChange={this.props.onChange.listChange}
            >
              Please list any awards or scholarships you have received.
              
            </List>
            <List
              name="projects"
              value={this.props.values.projects}
              onChange={this.props.onChange.listChange}
            >
              Please list any projects or competitions you have worked on.
              
            </List>
            <List
              name="extracurriculars"
              value={this.props.values.extracurriculars}
              onChange={this.props.onChange.listChange}
            >
              Please list any extracurricular activities that you have
              participated in. 
            </List>
            <List
              name="leadership"
              value={this.props.values.leadership}
              onChange={this.props.onChange.listChange}
            >
              Please list any leadership roles (role and organization) that you
              have held. For example, President, Student Government Association.
              
            </List>
            <List
              name="work"
              value={this.props.values.work}
              onChange={this.props.onChange.listChange}
            >
              Please list any work experience you have had. For example, any job
              (paid or unpaid), summer job programs, and internships. 
            </List>

            <Form.Row className="my-5">
              <Form.Group>
                <h6 className="font-weight-bold">Relevant Coursework</h6>
                <Form.Label>
                  Please list any coursework you have completed that is relevant
                  to your application.
                </Form.Label>
               { this.props.values.coursework.length > 0 &&  <Table className="my-3" striped hover bordered>
                  <thead>
                    <tr>
                      <th>Course Title</th>
                      <th>Date Taken</th>
                      <th>Grade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.values.coursework.map((c) => (
                      <tr key={c.title}>
                        <td>{c.title}</td>
                        <td>{format(c.dateBack,'MM/dd/yyyy')}</td>
                        <td>{c.grade}</td>
                        <td><Button variant="danger" onClick={() => {
                          this.props.onCourseworkDelete(c)
                        }}><BsTrash></BsTrash></Button></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>}
                <InputGroup className="">
                  {/* <InputGroup.Text>Course Title</InputGroup.Text> */}
                  <Form.Control placeholder="Course Title" value={this.state.courseworkTitle} onChange={this.handleChange} name="courseworkTitle"></Form.Control>
                  {/* <InputGroup.Text>Date Taken</InputGroup.Text> */}
                  <Form.Control
                    className="dateTaken mx-5"
                    type="date"
                    placeholder="mm/dd/yyyy"
                    required
                    name="dob"
                    aria-label="mm/dd/yyyy"
                    aria-describedby="mm/dd/yyyy"
                    onChange={this.handleDateChange}
                    value={this.state.courseworkDate}
                  />
                  {/* <InputGroup.Text>Grade Received</InputGroup.Text> */}
                  <Form.Control placeholder="Grade Received" value={this.state.courseworkGrade} onChange={this.handleChange} name="courseworkGrade"></Form.Control>
                  <Button
                  className="ml-4"
                  onClick={() => {
                    if(this.isCourseworkFieldsetComplete()){
                    this.props.onCourseworkAdd({
                    title: this.state.courseworkTitle,
                    date: this.state.courseworkDate,
                    dateBack: this.state.courseworkDateBack,
                    grade: this.state.courseworkGrade,
                  })
                   this.resetCoursework();
                }
                }
                }
                >
                  Add
                </Button>
                </InputGroup>
               
                {/* <div className="text-danger">
                  {this.props.validator.message(
                    "courseworkGrade",
                    this.state.courseworkGrade,
                    "required"
                  )}
                </div> */}
              </Form.Group>
            </Form.Row>

            <Form.Row className="mt-5">
              <Form.Group controlId="notes" as={Col}>
                <h6 className="font-weight-bold">Application Notes</h6>
                <Form.Label>
                  Please use this space to provide any additional information
                  that you believe is relevant to your application. For example,
                  any perceived weaknesses or additional courses/workshops that
                  you have completed that do not appear on your transcript.
                   (150 word limit)
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  required
                  name="notes"
                  placeholder="Write your message here"
                  aria-label="Write your message here"
                  aria-describedby="Write your message here"
                  onChange={this.props.onChange.handleChange}
                  value={this.props.values.notes}
                />
                <div id="word-counter">
                  <span
                    id="word-count"
                    className={
                      this.wordCount() > 150 ? "word-count-over" : "word-count"
                    }
                  >
                    {this.wordCount()}/150
                  </span>
                </div>
                {this.overCount() && (
                  <div className="text-danger">
                    Application notes are over maximum length!
                  </div>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row style={{ marginTop: "6em" }}>
              <Form.Group as={Col} className="text-left">
                <Button variant="outline-primary" onClick={this.props.prevStep}>
                  Prev
                </Button>
              </Form.Group>
              <Form.Group as={Col} className="text-right">
                <Button
                  className="next-btn"
                  variant="outline-primary"
                  onClick={this.props.nextStep}
                  disabled={this.overCount()}
                >
                  Next
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
