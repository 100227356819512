import React, { Component } from "react";
import {
  Form,
  Col,
  Button,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap";
import {
  BsPlus,
  BsFillDashCircleFill,
} from "react-icons/bs";
import "./page4.css";

export default class List extends Component {
  constructor(props) {
    super(props);

    this.appendList = this.appendList.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onKey = this.onKey.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.state = {
      input: "",
    };

    this.ReactTags = React.createRef();
  }

  removeItem(item) {
    var arr = this.props.value;
    arr.splice(arr.indexOf(item), 1);
    this.setState({ list: arr });
    this.props.onChange(arr,this.props.values);
  }

  addItem(name) {
    let newList = this.props.value;
    let input = this.state.input;
    if(input === "" || !input.replace(/\s/g, '').length) return;
    newList.push(input);
    this.setState({
      list: newList,
      input: "",
    });
    this.props.onChange(newList,this.props.name);
  }

  handleChange(e) {
    // console.log(e.target.value);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if(e.target.type !== "checkbox" && value.length > (this.props.charLimit || 50)){
      return
    }
    this.setState({ [e.target.name]: value });
  }

  appendList() {
    if (this.props.value.length) {
      return this.props.value.map((currentItem, index) => {
        return (
          <ListGroup.Item key={index}>
            {currentItem}
            <Button
              value={currentItem}
              onClick={(params) => {
                this.removeItem(currentItem);
              }}
            >
              <BsFillDashCircleFill></BsFillDashCircleFill>
            </Button>
          </ListGroup.Item>
        );
      });
    }
  }

  onKey(event) {
    if (event.key === "Enter") {
      this.addItem();
    }
  }

  render() {
    return (
      <Form.Row className="mt-5">
        <Form.Group controlId="formLeadership" as={Col}>
          <Form.Label>
            {this.props.children}
          </Form.Label>
          <InputGroup>
            <FormControl
              type="text"
              placeholder="Enter Item"
              name="input"
              aria-label="Enter Item"
              aria-describedby="Enter Item"
              onChange={this.handleChange}
              value={this.state.input}
              onKeyDown={this.onKey}
              onBlur={this.addItem}
            />
            <InputGroup.Append>
              <Button onClick={this.addItem}>
                <BsPlus />
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {!this.props.noListRender && <ListGroup>
            {this.appendList()}
          </ListGroup>}
        </Form.Group>
      </Form.Row>
    );
  }
}
