import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./page2.css";
export default class Page2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otherRace: false,
      otherRaceValue: "",
      selectedRaces: [],
    };

    this.onOtherRaceToggle = this.onOtherRaceToggle.bind(this);
  }

  componentDidMount() {}

  onOtherRaceToggle() {
    this.setState({ otherRace: !this.state.otherRace });
  }

  render() {
    if (this.state.redirect) {
      return <Link to={this.state.redirect} />;
    }
    return (
      <Row className="justify-content-center ">
        <Col md={6}>
          <Form noValidate validated={this.props.validated}>
            <Form.Row>
              <Form.Group as={Col}>
                <h6 className="font-weight-bold">Personal Information</h6>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  Ethnicity<em style={{ color: "red" }}>&#42;</em>
                </Form.Label>
                {this.props.raceOptionsFirst.map((o) => {
                  return (
                    <Form.Check
                      type="radio"
                      onChange={this.props.onChange.onEthnicityChange}
                      name="ethnicity"
                      key={o}
                      label={o}
                      value={o}
                      checked={this.props.selected.ethnicity === o}
                    />
                  );
                })}
                <div className="text-danger">
                  {this.props.validator.message(
                    "Ethnicity",
                    this.props.selected.ethnicity,
                    "required"
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              {" "}
              <Form.Group as={Col}>
                <Form.Label>
                  Race<em style={{ color: "red" }}>&#42;</em> (Check all that
                  apply)
                </Form.Label>
                {this.props.raceOptionsSecond.map((o) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      onChange={this.props.onChange.onRaceChange}
                      name="raceRadio"
                      key={o}
                      label={o}
                      value={o}
                      checked={this.props.selected.race.indexOf(o) >= 0}
                      disabled={
                        o !== "Prefer not to disclose" &&
                        this.props.selected.race.indexOf(
                          "Prefer not to disclose"
                        ) >= 0
                      }
                    />
                  );
                })}

                <Form.Check
                  type="checkbox"
                  onChange={this.props.onChange.onRaceChange}
                  label="Other"
                  value="Other"
                />
                {this.props.selected.race.indexOf("Other") >= 0 && (
                  <Form.Control
                    placeholder="Enter other race/ethnicity"
                    onChange={this.props.onChange.onOtherRace}
                  ></Form.Control>
                )}
                <div className="text-danger">
                  {this.props.validator.message(
                    "Race",
                    this.props.selected.race,
                    "required"
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  US Citizenship Status<em style={{ color: "red" }}>&#42;</em>
                </Form.Label>
                {this.props.selectList(
                  "formCitizenship",
                  "citizenshipStatus",
                  ["Select", "Citizen", "Non-Citizen"],
                  this.props.onChange.handleChange,
                  this.props.selected.citizenship
                )}
                <div className="text-danger">
                  {this.props.validator.message(
                    "Citizenship",
                    this.props.selected.citizenship,
                    "required"
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  Current Education Status
                  <em style={{ color: "red" }}>&#42;</em>
                </Form.Label>
                {this.props.selectList(
                  "formEducation",
                  "educationStatus",
                  [
                    "Select",
                    "Current High School Senior",
                    "Current College Freshman",
                    "Current College Sophomore",
                  ],
                  this.props.onChange.handleChange,
                  this.props.selected.education
                )}
                <div className="text-danger">
                  {this.props.validator.message(
                    "EducationStatus",
                    this.props.selected.education,
                    "required"
                  )}
                </div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  College Credit Hours Completed{" "}
                  <i>(At time of application submission)</i>
                  <em style={{ color: "red" }}>&#42;</em>
                </Form.Label>
                <FormControl
                  type="number"
                  min="0"
                  name="creditHours"
                  required
                  aria-label="College Credit Hours Completed"
                  value={this.props.selected.creditHours}
                  onChange={this.props.onChange.handleChange}
                />
                <div className="text-danger">
                  {this.props.validator.message(
                    "creditHours",
                    this.props.selected.creditHours,
                    "required"
                  )}
                </div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group controlId="formFirstName" as={Col}>
                <Form.Label>
                  GPA<em style={{ color: "red" }}>&#42;</em>
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type="number"
                    name="gpa"
                    required
                    placeholder="GPA"
                    aria-label="GPA"
                    aria-describedby="GPA"
                    min="0"
                    size="1"
                    onChange={this.props.onChange.handleChange}
                    value={this.props.selected.gpa}
                  />
                  <p
                    className="col-2 align-self-center"
                    style={{ marginBottom: 0 }}
                  >
                    Out of
                  </p>
                  <FormControl
                    type="number"
                    name="gpaOutof"
                    required
                    placeholder="GPA"
                    aria-label="GPA"
                    aria-describedby="GPA"
                    min="0"
                    onChange={this.props.onChange.handleChange}
                    value={this.props.selected.gpaOutof}
                  />
                </InputGroup>
                <div className="text-danger">
                  {this.props.validator.message(
                    "Gpa",
                    this.props.selected.gpa,
                    `required|numeric|max:${this.props.selected.gpaOutof},num`
                  )}
                </div>
                <div className="text-danger">
                  {this.props.validator.message(
                    "GpaOutof",
                    this.props.selected.gpaOutof,
                    "required|numeric"
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ marginTop: "6em" }}>
              <Form.Group as={Col} className="text-left">
                <Button
                  variant="outline-primary"
                  onClick={this.props.prevStep}
                  className="prev-btn"
                >
                  Prev
                </Button>
              </Form.Group>
              <Form.Group as={Col} className="text-right">
                <Button
                  variant="outline-primary"
                  onClick={this.props.nextStep}
                  className="next-btn"
                >
                  Next
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
