// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8q5wrTJLOaPTIrharabndnrSJJtXgXBM",
  authDomain: "ness-scholarship.firebaseapp.com",
  projectId: "ness-scholarship",
  storageBucket: "ness-scholarship.appspot.com",
  databaseURL: "https://ness-scholarship-default-rtdb.firebaseio.com",
  messagingSenderId: "576942046504",
  appId: "1:576942046504:web:540bd43ccc0ae697a7241d",
  measurementId: "G-QPPMCYZ3T1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)

export const auth = getAuth();
