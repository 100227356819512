import React from 'react'

export default function AsyncSpinner(props) {
  return (
    <div className="error-container">
    <h1>{props.message}</h1>
    <p>Please Wait</p>
</div>
  )
}
