import React from "react";
import { Container, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";

import dotenv from "dotenv";
dotenv.config();

var bucketName = "nextgen-scholarship-w2s";



AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: "us-east-1",
});

export default function Awardees() {
  const [images, setImages] = useState([]);
  const [list, setList] = useState(null);
  const [items, setItems] = useState([]);
  function onDocumentLoadSuccess(pdf) {
    // setNumPages(pdf.numPages);
  }

  function downloadFile(item) {
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName },
        signatureVersion: "v4",
      });

    s3.getObject({ Bucket: bucketName, Key: item.Key }, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        const blob = new Blob([data.Body], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.Key;
        link.click();
      }
    });
  }

  function deleteItem(item) {
    var confirm = window.confirm("Are you sure you want to delete this item?");
    if (!confirm) {
      return;
    }
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName },
        signatureVersion: "v4",
      });

    s3.deleteObject({ Bucket: bucketName, Key: item.Key }, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        s3.listObjectsV2({ Bucket: bucketName }, function (err, data) {
            if (err) {
              console.log(err, err.stack);
            } else {
              console.log(data);
              setItems(data.Contents);
            }
          });
      }
    });
  }

  useEffect(() => {
    var s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
      signatureVersion: "v4",
    });

    s3.listObjectsV2({ Bucket: bucketName }, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        setItems(data.Contents);
      }
    });
  }, []);

  return (
    <Container style={{ height: "100vh" }}>
      <h1>Awardees</h1>

      <h2>W-9 Forms</h2>

      <a href={list} target="_blank" rel="noreferrer">
        {list}
      </a>

      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Document</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <tr key={i}>
                <td>{item.Key}</td>
                <td>{item.LastModified.toString()}</td>
                <td><Button onClick={() => {downloadFile(item)}}>Download</Button></td>
                <td><Button variant="danger" onClick={() => {deleteItem(item)}}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}
