import { Button, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { BsArrowLeft, BsZoomIn, BsZoomOut, BsInfoCircle, BsInfo } from "react-icons/bs";
import Evaluation from "./evaluation.component";
import { pdfjs } from "react-pdf";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

var bucketName = "nextgen-scholarship-transcripts";
var s3params = { Bucket: bucketName, Key: "1.jpg" };

const tooltip = (
  <Tooltip placement="right" className="in" id="tooltip-right">
    <p>
      Please explain why you are interested in studying the major(s) you have
      selected. If you selected undecided please describe your areas of possible
      academic interest. Make sure to describe how your interests relate to data
      science, provide examples of how you have demonstrated your interest so
      far, and how you plan to pursue your interests in college.
    </p>
    <em>
      Use your response as an opportunity to tell a story about how your major
      connects with your career goals. What experiences or high school courses
      piqued your interest?{" "}
    </em>
  </Tooltip>
);

function encode(data) {
  let buf = Buffer.from(data);
  let base64 = buf.toString("base64");
  return base64;
}

function ApplicationDetail(props) {
  let params = useParams();
  const [images, setImages] = useState([]);
  const [imageType, setImageType] = useState();
  const [application, setApplication] = useState(
    props.apps.find((a) => a._id === params.id)
  );
  const [evaluation, setEvaluation] = useState();
  const [evalStarted, setEvalStarted] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  useEffect(() => {
    window.scrollTo(0, 0);
    setEvaluation(
      props.evaluations?.find(
        (e) => e.StudentID === params.id && e.UserID === props.user
      )
    );
    setEvalStarted(
      !!props.evaluations?.find(
        (e) => e.StudentID === params.id && e.UserID === props.user
      )
    );
    if (!application) return;
  }, [application, params, images, props.apps]);

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }

  useEffect(() => {
    if (images.length > 0) return;
    AWS.config.region = "us-east-1";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-1:e774ee60-7b0d-4f63-862c-31e169a873f0",
    });
    let file = application?.Files[0].replace(
      "https://nextgen-scholarship-transcripts.nextgen-scholarship-transcripts.s3.us-east-1.amazonaws.com/",
      ""
    );
    var s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
      signatureVersion: "v4",
    });
    var promise = s3.getSignedUrlPromise("getObject", {
      Bucket: bucketName,
      Key: decodeURI(file),
    });
    promise.then(
      function (url) {
        console.log(url);
        setImages((arr) => [...arr, url]);
        setImageType(application?.Files[0].substr(-3, 3).toLowerCase());
      },
      function (err) {
        console.log(err);
      }
    );
  }, []);

  return (
    <div>
      {application && (
        <div className="pb-5 mb-5">
          <h1>#{props.apps.indexOf(application) + 1}</h1>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            {
              <tbody>
                <tr>
                  <td>Discovery Method</td>
                  <td>{application.DiscoveryMode || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>Ethnicity</td>
                  <td>{application.Ethnicity}</td>
                </tr>
                <tr>
                  <td>Race</td>
                  <td>{application.Race.join(", ")}</td>
                </tr>
                <tr>
                  <td>Citizenship Status</td>
                  <td>{application.Current_citizenship_status}</td>
                </tr>
                <tr>
                  <td>Education Status</td>
                  <td>{application.Current_education_status}</td>
                </tr>
                <tr>
                  <td>GPA</td>
                  <td>
                    {application.Current_GPA} out of{" "}
                    {application.Current_GPA_Outof}
                  </td>
                </tr>
                <tr>
                  <td>Credit Hours</td>
                  <td>{application.Credit_hours}</td>
                </tr>
                {/* add w9 field */}
                <tr>
                  <td>Can Provide W9</td>
                  <td>{application.Can_Provide_W9 ? "Yes" : "No"}</td>
                  </tr>
                <tr>
                  <td>Major(s)</td>
                  <td>{application.Majors.join(",")}</td>
                </tr>
                <tr>
                  <td>
                    <p>Essay</p>
                    <OverlayTrigger placement="right" overlay={tooltip}>
              <BsInfoCircle color="#88F">  </BsInfoCircle>
                    </OverlayTrigger>
                  </td>
                  <td>{application.Essay}</td>
                </tr>
                <tr>
                  <td>Awards</td>
                  <td>{application.Awards_Scholarships.join(", ") || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>Projects</td>
                  <td>{application.Projects.join(",") || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>Extracurriculars</td>
                  <td>{application.Extracurriculars.join(",") || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>Leadership Roles</td>
                  <td>{application.Leadership_Roles.join(",") || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>App Notes</td>
                  <td>{application.Application_Notes || <i style={{color: 'red'}}>No response</i>}</td>
                </tr>
                <tr>
                  <td>Relevant Coursework</td>
                  <td style={{whiteSpace:'pre'}}>{application.Relevant_Coursework.map(x => `Course: ${x.title}\nDate Taken: ${ x.dateBack && format(new Date(x.dateBack),'MM/dd/yyyy')}\nGrade Received: ${x.grade}\n\n` )}</td>
                </tr>
              </tbody>
            }
          </Table>

          <h3>Transcript Files</h3>
          <div className="my-4">
            {images?.length &&
              images.map((img, index) => (
                <div key={index}>
                  {imageType?.toLowerCase() === "pdf" ? (
                    <div>
                      {/* <iframe src={img} type="application/pdf" style={{border:"none"}} width="1400" height="1000" frameBorder="0"></iframe> */}
                      <span style={{ gap: "2em", display: "flex" }}>
                        <Button
                          onClick={() => setActivePage(activePage - 1)}
                          disabled={activePage === 0}
                        >
                          Prev
                        </Button>
                        <Button onClick={() => setScale(scale - 0.2)}>
                          <BsZoomOut></BsZoomOut>
                        </Button>
                        <Button onClick={() => setScale(scale + 0.2)}>
                          <BsZoomIn></BsZoomIn>
                        </Button>
                        <Button
                          onClick={() => setActivePage(activePage + 1)}
                          disabled={activePage + 1 === numPages}
                        >
                          Next
                        </Button>
                      </span>
                      <span>
                        Page {activePage + 1} out of {numPages}
                      </span>
                      <Document
                        file={img}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page scale={scale} pageIndex={activePage}></Page>
                      </Document>

                      {/* <a
                      href={`data:${img.type};base64,${img.data}`}
                      download={application.LastName + ".pdf"}
                    >
                      <Button>Download PDF (File {index + 1})</Button>
                    </a> */}
                    </div>
                  ) : (
                    <img
                      src={img}
                      crossOrigin="anonymous"
                      alt="transcript"
                      style={{ maxHeight: "1000px" }}
                    ></img>
                  )}
                </div>
              ))}
          </div>

          <hr></hr>
          <h2>Evaluation</h2>
          <p>
            <b>
              Please review the evaluation guidelines{" "}
              <a
                target="_blank"
                href="https://docs.google.com/document/d/1ShopCLTA6Y7aHLp-BBaLRT-hixVIwxdHRn6qlJTUezY/edit"
              >
                here
              </a>
              .
            </b>
          </p>
          {!evalStarted && (
            <Button size="lg" onClick={() => setEvalStarted(true)}>
              Start Evaluation
            </Button>
          )}
          {evalStarted && (
            <Evaluation
              student={application._id}
              user={props.user}
              eval={evaluation}
              postSubmit={props.postSubmission}
            ></Evaluation>
          )}

          <div className="my-5">
            {" "}
            <Link to="/admin">
              <Button>
                <BsArrowLeft></BsArrowLeft> Back to App List
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationDetail;
