import React, { Component } from "react";
import {
  Container,
  Col,
  Form,
  Row,
  Button,
  FormControl,
} from "react-bootstrap";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import SimpleReactValidator from "simple-react-validator";
import{ init } from '@emailjs/browser';
import emailjs from 'emailjs-com';
init("user_cz5tCp9b83ZF9q5LOF0Og");

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.emailConfirmBlur = this.emailConfirmBlur.bind(this)
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      // validators: {
      //   confirmEmail: {
      //     message: "Confirm Email must match Email",
      //     rule: (val, params, validator) => {
      //       return (
      //         val.toLowerCase() ===
      //         params.map((p) => p.toLowerCase()).indexOf(val) >= 0
      //       );
      //     },
      //   },
      // },
    });
    this.state = {
      email: "",
      confirmEmail: "",
      password: "",
      invalidCredentials: false,
    };
  }

  handleChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  emailConfirmBlur(){
    if(!this.validator.allValid()){
      this.validator.showMessages();
    }
  }

  register() {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, this.props.email, this.props.password)
      .then((userCredential) => {
        emailjs.send('service_2yc676e', "template_7vrhr62",{from_name: this.props.email},"user_cz5tCp9b83ZF9q5LOF0Og");
        this.props.signIn();
      })
      .catch((error) => {
        this.setState({invalidCredentials: true})
      });
  }

  render() {
    return (
      <div>
        <Container fluid className="mt-5">
          <Row className="justify-content-center">
            <Col md={6}>
              <h1>Register</h1>
              <Form>
                <Form.Row>
                  <Form.Group controlId="formEmail" as={Col}>
                    <Form.Label>
                      Email<em style={{ color: "red" }}>&#42;</em>
                    </Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Email"
                      required
                      name="email"
                      aria-label="Email"
                      aria-describedby="Email"
                      onChange={this.props.onChange}
                      value={this.props.email}
                    />
                    <div className="text-danger">
                      {this.validator.message(
                        "Email",
                        this.props.email,
                        "required|email"
                      )}
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formConfirmEmail" as={Col}>
                    <Form.Label>
                      Confirm Email<em style={{ color: "red" }}>&#42;</em>
                    </Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Confirm Email"
                      required
                      name="confirmEmail"
                      aria-label="EmailConfirm"
                      aria-describedby="EmailConfirm"
                      onChange={this.props.onChange}
                      onBlur={this.emailConfirmBlur}
                      value={this.props.confirmEmail}
                    />
                    <div className="text-danger">
                      {this.validator.message(
                        "confirmEmail",
                        this.props.confirmEmail,
                        `required|in:${this.props.email}`,
                        { messages: { in: "Emails need to match!" } }
                      )}
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row id="sign-in-dialog" className="">
                  <Form.Group as={Col}>
                    <Form.Label>Password<em style={{ color: "red" }}>&#42;</em></Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      name="password"
                      aria-label="password"
                      aria-describedby="password"
                      value={this.props.password}
                      onChange={this.props.onChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") this.register();
                      }}
                    />
                    <Button
                      className="mt-2"
                      variant="outline-primary"
                      disabled={!this.props.email || !this.props.confirmEmail || !this.props.password}
                      onClick={this.register}
                    >
                      Register
                    </Button>
                    {this.state.invalidCredentials && (
                      <div className="text-danger mt-1">
                        Invalid Registration
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
