import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";

import { BsPencil } from "react-icons/bs";
import React from "react";

export default function YesNoDiscuss(props) {
  const [showNote, setShowNote] = useState(false);
  const [editable, setEditable] = useState(props.note === "");

  function addNote() {
    setShowNote(true);
  }
  function saveNote(event){
    setEditable(false);
    setShowNote(false);
  }
  function editNote(){
    setEditable(true);
  }

  return (
    <div className="my-4">
      <label style={{ fontSize: "14pt", fontWeight: "600" }}>
        {props.label}
        <span style={{ color: "red" }}>*</span>
      </label>
      <div style={{display: "flex"}}>
        {["Yes", "No", "Discuss"].map((x) => {
          return (
            <span className="mx-5" key={x}>
              <input
                type="radio"
                required
                id={x}
                name={props.name}
                value={x}
                checked={props.val === x}
                onChange={(e) => props.onChange(e, props.name)}
              ></input>{" "}
              <label htmlFor={x}>{x}</label>
            </span>
          );
        })}
        {props.val === "Discuss" && <Form.Control as="textarea" name={props.name} style={{width:"100%"}} value={props.note} onChange={props.onNotesChange}></Form.Control>}
      </div>
    </div>
  );
}
