import React from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <div>

      <Navbar expand="lg" className="bg-body-tertiary" style={{backgroundColor: "#F1F5F4"}}>
        <Container>
          <Navbar.Brand href="#home">Nextgen Scholarship Admin Portal</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <div
              className="m-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className="">
                { (
                  <Link variant="link" to="/settings" className="pb-2 px-1">
                    Settings
                  </Link>
                )}
              </span>
              <span>
                <span className="px-1">Logged in as</span>
                <Button
                  variant="link"

                  className="pb-2 px-1"
                >
                  Logout
                </Button>
              </span>
            </div> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              
              <Nav.Link href="/admin/apps">Apps</Nav.Link>
              <Nav.Link href="/admin/evals">Evals</Nav.Link>
              <Nav.Link href="/admin/stats">Stats</Nav.Link>
              <Nav.Link href="/admin/report">Report</Nav.Link>
              <Nav.Link href="/admin/settings">Settings</Nav.Link>
              <Nav.Link href="/admin/awardees">Awardees</Nav.Link>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
