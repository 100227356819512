import React from "react";
import { BsCheck, BsCircleFill } from "react-icons/bs";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import _, { add } from "lodash";
import { NUMBER_OF_EVALUATORS} from "../constants";

const filteredRaces = [
  "American Indian or Alaska Native",
  "Black or African American",
  "Native Hawaiian or Pacific Islander",
  "Other",
];

export default function EvaluationList(props) {
  function totalScore(e) {
    if (!e) return;
    return (
      e.OverallScore.Value +
      e.BonusScore.Value +
      _.sum(e.Rubric.map((x) => x.Value))
    );
  }

  function appEvaluatorMap() {
    let res = this.props.apps.map((x, index) => [index % NUMBER_OF_EVALUATORS , (index + 1) % NUMBER_OF_EVALUATORS]);
    // console.log(res);
    return res;
  }

  function evaluationManifest() {}

  function isEligible(a) {
    return a.Race.some((r) => filteredRaces.includes(r));
    // && a.Current_citizenship_status === "Citizen"
  }

  return (
    <div>
      <h1>Evaluation List</h1>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Reviewer</th>
            <th>For</th>
            <th></th>
            <th>Eligibility</th>
            <th>Transcript Problem</th>
            <th>Score (26)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.evals &&
            props.evals
              .sort((a, b) => a.UserID.localeCompare(b.UserID)) // Sort by UserID
              .map((evaluation, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td>
                      Reviewer
                      {" " +
                        (props.roles?.findIndex(
                          (x) => x.user === evaluation.UserID
                        ) + 1) 
                      }
                    </td>
                    <td>
                      {
                        props.apps.find((x) => x._id === evaluation.StudentID)
                          ?.index
                      }
                    </td>
                    <td>
                      {/* {
                        props.apps.find((x) => x._id === evaluation.StudentID)
                          ?.Email
                      } */}
                    </td>
                    <td>{evaluation.Eligibility[0].Value}</td>
                    <td>{evaluation.Eligibility[1].Value}</td>
                    <td>{totalScore(evaluation)}</td>
                    <Link to={`viewevaluation/${evaluation.StudentID}`}>
                      <Button>View</Button>
                    </Link>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      <Table striped bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>Reviewer</th>
            <th>Status</th>
            <th>View Eval</th>
          </tr>
        </thead>
        <tbody>
          {props.apps &&
            _.sortBy(
              props.apps.filter((a) => isEligible(a)),
              [
                function (o) {
                  return o.Current_citizenship_status;
                },
              ]
            )
              .map((x, index) => ({
                ...x,
                evaluators: [index % NUMBER_OF_EVALUATORS, (index + 1) % NUMBER_OF_EVALUATORS],
              }))
              .map((app, index) => {
                return app.evaluators.map((e, i) => {
                  return (
                    <tr key={index + i}>
                      <td>{index + i}</td>
                      <td>
                        Reviewer {e + 1} for {app.Email}
                      </td>
                      <td>
                        {props.evals.filter(
                          (ev) =>
                            ev.StudentID === app._id &&
                            ev.UserID === props.roles[e].user
                        ).length > 0
                          ? "Done"
                          : "Not started"}
                      </td>
                      <td>

                       
                      </td>
                    </tr>
                  );
                });
              })}
        </tbody>
      </Table>
    </div>
  );
}
