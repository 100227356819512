import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Admin from "./components/admin.component";
import Index from "./components/index.component";
import Settings from "./components/settings.component";
import Stats from "./views/stats.view";
import W2Upload from "./components/w2upload.component";
import ApplicationDetail from "./components/applicationdetail.component";
import Awardees from "./components/awardees.component";
import dotenv from "dotenv";

dotenv.config();

function App() {
  return (
    <Router>
      <div>
        <div
          style={{
            backgroundColor: "white",
            paddingBottom: "100px",
            paddingTop: "25px",
          }}
        >
          <div className="row">
            <div className="col">
              {/* Router definition: All routes are defined within the Routes component below */}
              <Routes>
                <Route path="/" exact element={<Index />} />
                {/* <Route path="admin/awardees" element={<Awardees />} /> */}
                <Route path="/admin/*" element={<Admin />}></Route>
                <Route path="/settings" element={<Settings />}></Route>
  
                <Route path="/w9upload" element={<W2Upload />} />
                
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                ></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
