import React, { Component,  } from 'react';
import { Form, } from "react-bootstrap";

export default class DynamicSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <Form.Control as="select" name={this.props.name} onChange={(e) => this.props.onSelectChange(e)} value={this.props.value} >
                {this.props.options ? this.props.options.map((x) => <option value={(x==="Select")?'':x} key={x}>{x}</option>) : console.log(this.props.options)}
            </Form.Control>
        );
    }
}