import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'
import LogRocket from 'logrocket';
LogRocket.init('ivtubk/nextgen-scholarship-portal');

axios.defaults.baseURL = "https://nextgenscholarship.org/api/"
// axios.defaults.baseURL = "http://localhost:3002/api/"



ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
