import React from "react";

import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";

export default function Assignments(props) {
  useEffect(() => {}, []);

  function roleAssigned(r){
      return r.user !== "";
  }
 
  return (
    <div className="my-5">
      <h3>Choose an Evaluator Assignment</h3>
      {props.roles &&
        props.roles.map((r, i) => 
        {
          return ( 
            <Button
              key={i}
              name={i}
              onClick={props.onClick}
              style={{ margin: "10px 10px" , textDecoration: roleAssigned(r) ? "line-through" : ""}}
              disabled={roleAssigned(r)}
            >
              {" "}
              Evaluator {i + 1}
            </Button>)
         
        }
        )}
    </div>
  );
}
