import React, { Component } from 'react'
import "./error.css"
export default class Error extends Component {
    render() {
        return (
            <div className="error-container">
                <h1 className="error-header">Error</h1>
                <p>If this error persists, please contact ness.nextgenscholarship@gmail.com</p>
                <p>Return to <a href="https://nextgenscholarship.nestat.org/">https://nextgenscholarship.nestat.org/</a></p>
            </div>
        )
    }
}
