import { BsCheck, BsCircleFill } from "react-icons/bs";
import { Button, Table } from "react-bootstrap";
import React, { Component } from "react";
import _, { add } from "lodash";

import { Link } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { NUMBER_OF_EVALUATORS} from "../constants";

const filteredRaces = [
  "American Indian or Alaska Native",
  "Black or African American",
  "Native Hawaiian or Pacific Islander",
  "Other",
];



export default class AppTable extends Component {
  constructor(props) {
    super(props);
    this.totalScore = this.totalScore.bind(this);
    this.isEligible = this.isEligible.bind(this);
    this.evalCompleted = this.evalCompleted.bind(this);
    this.isAppAssignedToEvaluator = this.isAppAssignedToEvaluator.bind(this);
    this.toggleShowAll = this.toggleShowAll.bind(this);
    this.appsToDisplay = this.appsToDisplay.bind(this);
    this.appEvaluatorMap = this.appEvaluatorMap.bind(this);
    this.completedStatus = this.completedStatus.bind(this);
    this.userIsAdmin = this.userIsAdmin.bind(this)
    this.userIsReviewer = this.userIsReviewer.bind(this)
    this.toggle = this.toggle.bind(this);
    this.currentEvaluatorsAssignedApps =
      this.currentEvaluatorsAssignedApps.bind(this);
    this.state = {
      show: true,
      showAllRows: true,
      userID: props.userID,
      userRoleNumber: props.roleNumber,
      eligibleApps: _.sortBy(
        props.apps.filter((a) => this.isEligible(a)),
        [
          function (o) {
            return o.DateOfSubmission;
          },
        ]
      ).map((x, index) => ({ ...x, evaluators: [index % NUMBER_OF_EVALUATORS, (index + 1) % NUMBER_OF_EVALUATORS] })),
    };
  }

  toggle() {
    let s = this.state.show;
    this.setState({ show: !s });
  }

  appEvaluatorMap() {
    let res = this.props.apps.map((x, index) => [index % NUMBER_OF_EVALUATORS, (index + 1) % NUMBER_OF_EVALUATORS]);
    // console.log(res);
    return res;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  totalScore(e) {
    if (!e) return;
    return (
      e.OverallScore.Value +
      e.BonusScore.Value +
      _.sum(e.Rubric.map((x) => x.Value))
    );
  }

  isEligible(a) {
    return a.Race.some((r) => filteredRaces.includes(r));
    // && a.Current_citizenship_status === "Citizen"
  }

  userIsAdmin() {
    return this.props.user?.role?.toLowerCase() === "admin";
  }

  userIsReviewer() {
    return this.props.user?.role?.toLowerCase() === "reviewer";
  }

  evalCompleted(id) {
    return (
      this.props.evaluations.filter(
        (x) => x.StudentID === id && x.UserID === this.props.userID
      ).length > 0
    );
  }

  isAppAssignedToEvaluator(app) {
    return this.currentEvaluatorsAssignedApps().includes(app);
  }

  toggleShowAll() {
    let currentState = this.state.showAllRows;
    this.setState({ showAllRows: !currentState });
  }

  appsToDisplay() {
    if (this.state.showAllRows) {
      return this.state.eligibleApps;
    } else {
      return this.currentEvaluatorsAssignedApps();
    }
  }

  completedStatus(app) {
    let result = this.evalCompleted(app._id) ? (
      <span>
        Submitted <BsCheck style={{ color: "green" }}></BsCheck>
      </span>
    ) : (
      <span>
        Not Started <BsCircleFill style={{ color: "red" }}></BsCircleFill>
      </span>
    );
    if (!this.isAppAssignedToEvaluator(app)) result = "N/A";
    return result;
  }

  currentEvaluatorsAssignedApps() {
    return this.state.eligibleApps.filter((x) =>
      x.evaluators.includes(this.state.userRoleNumber)
    );
  }

  render() {
    return (
      <div>
        <h2 style={{ background: "lightgrey" }} className="p-2">
          Evaluation Assignments
          <Button className="" style={{ float: "right" }} onClick={this.toggle}>
            {!this.state.show ? "Show" : "Hide"}
          </Button>
        </h2>
        {this.state.show && <div>
          <h3>
            {this.state.eligibleApps.length} Eligible out of{" "}
            {this.props.apps.length}
          </h3>
          <label htmlFor="show-my-evals">
            Show only my evaluations
            <input
              type="checkbox"
              id="show-my-evals"
              className="ml-2"
              checked={!this.state.showAllRows}
              onChange={this.toggleShowAll}
            ></input>
          </label>
          { 
            <Table striped bordered>
              <thead>
                <tr>
                  <th>#</th>
                  {/* <th>Assigned to</th> */}
                  {/* <th>Transcript?</th> */}
                  {/* <th>Race</th> */}
                  {/* <th>Last Name</th> */}
                  {/* <th>Citizenship</th> */}
                  <th>Evaluation Status</th>
                  <th>Score Given (out of 26)</th>
                </tr>
              </thead>
              <tbody>
                {this.appsToDisplay() &&
                  this.appsToDisplay().map((app, index) => {
                    return (
                      <tr key={app.index}>
                        <td>{app.index}</td>
                        {/* <td>{app.evaluators.map((x) => x + 1).join(",")}</td> */}
                        {/* <td>{app.Files.length}</td> */}
                        {/* <td style={{overflow:"ellipsis", width: "200px"}}>{app.Race.join("/")}</td> */}
                        {/* <td>{app.Current_citizenship_status}</td> */}
                        {/* <td>{app.LastName}</td> */}
                        {/* <td>{app.Email}</td> */}
                        {/* <td>
                      {moment(app.DateOfSubmission).format("MM/DD/YYYY")}
                    </td> */}
                        <td>{this.completedStatus(app)}</td>
                        <td>
                          {this.totalScore(
                            this.props.evaluations.find(
                              (x) =>
                                x.StudentID === app._id &&
                                x.UserID === this.props.userID
                            )
                          )}
                        </td>
                        <td>
                          <Link to={`${app._id}`}>
                            <Button>View</Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          }
        </div>}
      </div>
    );
  }
}
