import React from "react";

export default function Stats() {
  return (
    <div>
      <h3 className="my-5">Stats Dashboard</h3>
      <iframe
        title="MongoDB Charts"
        style={{
          background: "#F1F5F4",
          border: "none",
          borderRadius: "2px",
          boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
          width: "100vw",
          height: "100vh",
        }}
        src="https://charts.mongodb.com/charts-ness-ngs-hciki/embed/dashboards?id=ef1a78dd-03ea-4cf1-a800-cd1b1d15f4ef&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"
      ></iframe>
    </div>
  );
}
