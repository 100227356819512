import React from "react";
import { useState } from "react";
import { Container, Form, Button, FormControl } from "react-bootstrap";
import { child, get, getDatabase, ref, set } from "firebase/database"

export default function Settings(props) {
  const [openDate, setopenDate] = useState("");
  const [closeDate, setcloseDate] = useState("");

  function handleDateChange(e) {
    if (Date.parse(e.target.value)) {
      var doo = new Date(e.target.value);
      var date = new Date(
        doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
      );
      this.setState({ dobBack: date, dob: e.target.value });
    }
  }

  function handleChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  function resetEvaluators() {
    const dbRef = ref(getDatabase());
    //get list of evaluator roles
    const roles = []
    get(child(dbRef, `evaluatorRoles/`)).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          roles.push(childSnapshot.val());
        });
        if(window.confirm("Are you sure you want to reset all evaluator roles?")) {
          for(let i = 0; i < roles.length; i++) {
            set(child(dbRef, `evaluatorRoles/${i}/user`), "");
          }
        }
      }
    });
  }

  return (
    <div>
      <Container
        fluid
        style={{ minHeight: "100vh", padding: "0 100px 0 100px" }}
      >
        <h1>Admin Settings</h1>
        <br></br>
        {/* <div className="my-5"><Button>Set new App</Button></div> */}
        <Button onClick={resetEvaluators}>Reset Evaluator Roles</Button>
        <div className="my-2">
          {" "}
          <Form.Label>
            Opening Date
            <em style={{ color: "red" }}>&#42;</em>
          </Form.Label>
          <FormControl
            className="dob"
            type="date"
            placeholder="mm/dd/yyyy"
            required
            name="dob"
            aria-label="mm/dd/yyyy"
            aria-describedby="mm/dd/yyyy"
            onChange={handleDateChange}
            value={openDate}
          />
        </div>
        <div className="my-5">
          <Form.Label>
            Close Date
            <em style={{ color: "red" }}>&#42;</em>
          </Form.Label>
          <FormControl
            className="dob"
            type="date"
            placeholder="mm/dd/yyyy"
            required
            name="dob"
            aria-label="mm/dd/yyyy"
            aria-describedby="mm/dd/yyyy"
            onChange={handleDateChange}
            value={closeDate}
          />
        </div>
        <div>
          <Form.Control
            as="textarea"
            rows={20}
            required
            name="intro-message"
            onChange={handleChange}
            // value={props.values.essay}
          />
        </div>

        <div className="my-5">
          <Button>Save</Button>
        </div>
      </Container>
    </div>
  );
}
