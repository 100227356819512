import { Alert, Button, Form } from "react-bootstrap";
import _, { add } from "lodash";
import { useEffect, useState } from "react";

import BeatLoader from "react-spinners/BeatLoader";
import { BsCheck } from "react-icons/bs"
import Error from "./error.component";
import React from "react";
import Rubric from "./rubric.component";
import YesNoDiscuss from "./yesnodiscuss.component";
import axios from "axios";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Evaluation(props) {
  const [submitted, setSubmitted] = useState(!!props.eval);
  const [error, setError] = useState(false);
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#020e2f");
  const labels = ["Unsatisfactory", "Fair", "Good", "Outstanding"];
  const addtlLabels = ["None", "Partial/Maybe", "Yes"];
  const [eligibility, setEligibility] = useState();
  const [eligibilityNotes, setEligibilityNotes] = useState();
  const [transcriptProblem, setTranscriptProblem] = useState();
  const [transcriptNotes, setTranscriptNotes] = useState();
  const [overallRubric, setOverallRubric] = useState([
    {
      Name: "Application provides evidence of qualities that make them likely to succeed in data science or a data science-related career",
      Text: [
        "No or weak evidence of qualities provided",
        "Some evidence of qualities provided",
        "Sufficient evidence of qualities provided",
        "Overwhelming evidence of qualities provided",
      ],
      Value: 0,
      Note: "",
    },
  ]);
  const [addtlExperiences, setAddtlExperiences] = useState([
    {
      Name: "Applicant has provided additional information that would cause me to improve the score of the essay.",
      Text: [
        "No new, relevant items",
        "1-2 new, related items",
        "3 or more new, related items",
      ],
      Value: -1,
      Note: "",
    },
  ]);
  const [rubric, setRubric] = useState([
    {
      Name: "Answers Essay Prompt",
      Text: [
        "Did not address essay prompt",
        "Some but not all parts of  prompt were answered. Weak connection made to data science.",
        "Essay prompt was addressed with elaboration/example. Adequate connection made to data science. ",
        "Essay prompt was addressed with clear picture of the connection between data science and intended major",
      ],
      Value: 0,
      Note: "",
    },
    {
      Name: "Shows interest in data science",
      Text: [
        "Does not address involvement/ interest in data science.[not through extracurricular activities or coursework or other experiences]",
        "Loosely addresses involvement/interest in data science.[through coursework (e.g.,MOOCs).]",
        "Details strong involvement/ interest in data science. [through extracurriculars or coursework.]",
        "Details strong involvement/interest in data science. Essay also details the impact these experiences have had on their acquisition of data science skills.  [through extracurriculars or coursework]",
      ],
      Value: 0,
      Note: "",
    },
    {
      Name: "Passionate about intended career ",
      Text: [
        "Does not address career pursuits.[no mention of a plan for college; no mention of career]",
        "Only loosely addresses career pursuits. ",
        "Demonstrates strong evidence of future career pursuits. It shows evidence of post college plans.",

        "Demonstrates strong evidence of future career pursuits. It shows evidence of post college plans and how the applicant plans to be a leader in the field.[plan for college, plan for future career, future leadership goals]",
      ],
      Value: 0,
      Note: "",
    },
    {
      Name: "Organization and Clarity",
      Text: [
        "Lacks both development and organization. [no clear narrative, ideas are all over the place, hard to follow]",
        "Shows some development and organization.",
        "Shows development and organization, but lacks clarity in some areas.",
        "Shows development, organization, and clarity.[very clear narrative, easy to follow, ideas are good and concise]",
      ],
      Value: 0,
      Note: "",
    },
    {
      Name: "Quality of Writing",
      Text: [
        "Mechanical errors interfere with understanding. [incomplete sentences; spelling and/or grammar mistakes, start sentences with the same word]",
        "Mechanical errors that interfere with understanding; however, they show some understanding of their chosen career path.",
        "Few mechanical errors, but does not interfere with understanding. Good content which demonstrates an understanding of either data science and their chosen career path.",
        "Minimal mechanical errors with excellent content that demonstrates an understanding of how data science can be applied to their chosen career path.",
      ],
      Value: 0,
      Note: "",
    },
  ]);

  function allRequiredFields(){
    var rFilled = rubric.filter(x => x.Value === 0).length === 0;
    var aFilled = addtlExperiences[0].Value >= 0;
    var oFilled = overallRubric[0].Value > 0 ;
    return (eligibility === "No" || transcriptProblem === "Yes") || (!!eligibility && !!transcriptProblem && rFilled && aFilled && oFilled);
  }

  function totalScore() {
    return (
      overallRubric[0].Value +
      addtlExperiences[0].Value +
      _.sum(rubric.map((x) => x.Value))
    );
  }

  function totalScorePercent() {
    return "(" + ((totalScore() / 26) * 100).toFixed(2) + "%)";
  }

  function handleNotesChange(event) {
    var r = [...rubric];
    r.find((x) => x.Name === event.target.name).Note = event.target.value;
    setRubric(r);
  }

  function handleRubricChange(name, value) {
    var r = [...rubric];
    r.find((x) => x.Name === name).Value = value;
    setRubric(r);
  }

  function handleOverallChange(name, value) {
    var r = [...overallRubric];
    r[0].Value = value;
    setOverallRubric(r);
  }

  function handleOverallNotesChange(event) {
    var r = [...overallRubric];
    r[0].Note = event.target.value;
    setOverallRubric(r);
  }

  function handleBonusChange(name, value) {
    var b = [...addtlExperiences];
    b[0].Value = value;
    setAddtlExperiences(b);
  }

  function handleBonusNoteChange(event) {
    var r = [...addtlExperiences];
    r[0].Note = event.target.value;
    setAddtlExperiences(r);
  }

  function handleError(err){
    console.error(err);
    setError(true);
  }

  function handleSuccess(){
    props.postSubmit();
    setLoading(false);
    setSubmitted(true);
  }

  function submitForm() {
    setLoading(true);
    var mappedVals = {};
    mappedVals["student"] = props.student;
    mappedVals["user"] = props.user;
    mappedVals["rubric"] = rubric;
    mappedVals["overall"] = overallRubric[0];
    mappedVals["bonus"] = addtlExperiences[0];
    mappedVals["eligibility"] = [
      { Name: "eligibility", Value: eligibility, Note: eligibilityNotes },
      { Name: "transcript", Value: transcriptProblem, Note: transcriptNotes },
    ];
    axios.post("/evals", mappedVals).then(handleSuccess).catch(handleError);
  }

  useEffect(() => {
    if (props.eval) {
      if (props.eval.OverallScore) setOverallRubric([props.eval.OverallScore]);
      if (props.eval.BonusScore) setAddtlExperiences([props.eval.BonusScore]);
      if (props.eval.Rubric) setRubric(props.eval.Rubric);
      var elig = props.eval?.Eligibility.find((x) => x.Name === "eligibility");
      setEligibility(elig.Value);
      setEligibilityNotes(elig.Note);
      var trans = props.eval?.Eligibility.find((x) => x.Name === "transcript");
      setTranscriptProblem(trans.Value);
      setTranscriptNotes(trans.Note);
    }
  }, []);

  return (
    <div className="my-5">
     {error && <Error></Error>}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <YesNoDiscuss
          label={"Eligibility"}
          val={eligibility}
          note={eligibilityNotes}
          name="eligibility"
          onChange={(e) => setEligibility(e.target.value)}
          onNotesChange={(e) => setEligibilityNotes(e.target.value)}
        ></YesNoDiscuss>
        <YesNoDiscuss
          label={"Problems with Transcript"}
          val={transcriptProblem}
          note={transcriptNotes}
          name="transcript"
          onChange={(e) => setTranscriptProblem(e.target.value)}
          onNotesChange={(e) => setTranscriptNotes(e.target.value)}
        ></YesNoDiscuss>{ (eligibility !== "No" && transcriptProblem !== "Yes") &&<div>
        <Rubric
          name="Essay"
          showTotal
          elements={rubric}
          labels={labels}
          min={1}
          max={4}
          onChange={handleRubricChange}
          onNotesChange={handleNotesChange}
        ></Rubric>
        <Rubric
          name="Overall Score"
          elements={overallRubric}
          labels={labels}
          min={1}
          max={4}
          onChange={handleOverallChange}
          onNotesChange={handleOverallNotesChange}
        ></Rubric>
        <Rubric
          name="Additional Experiences"
          labels={addtlLabels}
          elements={addtlExperiences}
          min={0}
          max={2}
          onChange={handleBonusChange}
          onNotesChange={handleBonusNoteChange}
        ></Rubric>

        <div style={{ fontSize: "20pt" }}>
          <b>
            Total Score:
            {totalScore() >= 0 && (
              <span>
                {" " + totalScore() + "/" + (2 + 4 + 20) + " " + totalScorePercent()}
              </span>
            )}
          </b>
        </div></div>
}
        <div>
          {!allRequiredFields() && <Alert variant="warning">Please fill in all fields and rubric rows</Alert>}
          {!loading && !submitted && <Button disabled={!allRequiredFields()} className="my-2" type="submit" onClick={submitForm}>
            Submit
          </Button>}
          <BeatLoader
            color={color}
            loading={loading}
            css={override}
            size={10}
          ></BeatLoader>
          {submitted && <span> Submitted <BsCheck style={{color:"green", fontSize: "20pt"}}></BsCheck></span>}
        </div>
      </Form>
    </div>
  );
}
