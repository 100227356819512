import "firebaseui/dist/firebaseui.css";

import { Button, Container, Table } from "react-bootstrap";
import React, { Component } from "react";
import { Route, Routes, Link, Switch } from "react-router-dom";
import { child, get, getDatabase, ref, set } from "firebase/database";
import Assignments from "./assignments.component";
import AppTable from "./apptable.component";
import ApplicationDetail from "./applicationdetail.component";
import Register from "./register.component";
import SignIn from "./signin.component";
import Settings from "./settings.component";
import Report from "./report.component";
import { auth } from "../firebase";
import EvaluationView from "./evaluationview.component";
import axios from "axios";
import _ from "lodash";
import { signInWithEmailAndPassword } from "firebase/auth";
import EvaluationList from "./evaluationlist.component";
import { NavLink } from "react-router-dom";
import jsonwebtoken from "jsonwebtoken";
import NavBar from "./navbar.component";
import Stats from "./stats.component";
import Awardees from "./awardees.component";

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.checkRole = this.checkRole.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getEvaluatorRoles = this.getEvaluatorRoles.bind(this);
    this.signIn = this.signIn.bind(this);
    this.assignRole = this.assignRole.bind(this);
    this.userIsAdmin = this.userIsAdmin.bind(this);
    this.getEvals = this.getEvals.bind(this);
    this.getMatchingApp = this.getMatchingApp.bind(this);
    this.getMatchingEvals = this.getMatchingEvals.bind(this);
    this.totalScore = this.totalScore.bind(this);
    this.avgScore = this.avgScore.bind(this);
    this.load = this.load.bind(this);
    this.changeTab = this.changeTab.bind(this);
    // this.assignedApps = this.assignedApps.bind(this)
    this.toggleCharts = this.toggleCharts.bind(this);
    this.getEvaluatorAssignment = this.getEvaluatorAssignment.bind(this);
    this.state = {
      apps: [],
      activeTab: "main",
      user: null,
      users: [],
      evals: null,
      accts: [],
      roles: null,
      showDetail: false,
      selectedApp: null,
      signedInUser: sessionStorage.getItem("user"),
      uid: "",
      inAllowList: false,
      email: "",
      password: "",
      invalidCredentials: false,
      token: null,
      register: false,
      showEvalList: false,
      showCharts: false,
      firebaseUser: null,
      // showSettings: false,
    };
  }

  getEvals() {
    axios
      .get("/evals/")
      .then((res) => {
        // console.log(res.data);
        var userJoinedData = res.data.filter(
          (x) => x.UserID === this.state.uid
        );
        var allData = res.data;
        // var allData = res.data;
        if (this.userIsAdmin()) {
          this.setState({ evals: allData, showEvalList: true });
        } else this.setState({ evals: userJoinedData });
      })
      .catch((err) => console.log(err));
  }

  load() {
    axios
      .get("/students/")
      .then((res) =>
        this.setState({
          apps: res.data.map((a, i) => ({
            ...a,
            index: "Applicant " + (i + 1),
          })),
        })
      )
      .catch((err) => console.log(err));
    this.getEvals();
  }

  getEvaluatorRoles() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `evaluatorRoles/`))
      .then((snapshot) => {
        this.setState({ roles: snapshot.val().filter((x) => x !== null) });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  checkRole(user) {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${user.uid}`))
      .then((snapshot) => {
        this.setState({
          inAllowList: snapshot.exists(),
        });
        sessionStorage.setItem("user", JSON.stringify(user));

        this.setState({
          signedInUser: true,
          uid: user.uid,
          user: snapshot.val(),
        });
        this.load();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUsers() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users`))
      .then((snapshot) => {
        this.setState({
          users: snapshot.val(),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  signIn() {
    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.setState({ firebaseUser: user });
        this.checkRole(user);
        this.getEvaluatorRoles();
        sessionStorage.setItem("token", this.state.firebaseUser.accessToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.state.firebaseUser.accessToken}`;
        this.load();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this.setState({ invalidCredentials: true });
      });
  }

  handleChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  signOut() {
    this.setState({ signedInUser: false, token: null });
    sessionStorage.removeItem("user");
  }

  userIsAdmin() {
    return this.state.user?.role.toLowerCase() === "admin";
  }

  userIsReviewer() {
    return this.state.user?.role.toLowerCase() === "reviewer";
  }

  evaluatorHasAssignment() {
    if (this.state.roles)
      return (
        this.state.roles.filter((x) => x.user === this.state.uid).length > 0
      );
  }

  changeTab(tab) {
    this.setState({ activeTab: tab });
  }

  toggleCharts() {
    this.setState({ showCharts: !this.state.showCharts });
  }

  getEvaluatorAssignment() {
    if (this.state.roles)
      return this.state.roles.findIndex((x) => x.user === this.state.uid);
  }

  assignRole(e) {
    const dbRef = ref(getDatabase());
    // alert(e.target.name);
    set(child(dbRef, `evaluatorRoles/${e.target.name}/user`), this.state.uid);
    this.getEvaluatorRoles();
  }

  getMatchingApp(id) {
    return this.state.apps.find((a) => a._id === id);
  }

  getMatchingEvals(id) {
    return this.state.evals.filter((e) => e.StudentID === id);
  }

  totalScore(e) {
    if (!e) return;
    return (
      e.OverallScore.Value +
      e.BonusScore.Value +
      _.sum(e.Rubric.map((x) => x.Value))
    );
  }

  avgScore(evals) {
    if (evals.length < 2) return "";
    return (this.totalScore(evals[0]) + this.totalScore(evals[1])) / 2;
  }

  // toggleSettings(){
  //   let current = this.state.showSettings;
  //   this.setState({showSettings: !current})
  // }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.signedInUser) {
      var userObj = JSON.parse(sessionStorage.getItem("user"));
      var token = sessionStorage.getItem("token");
      this.setState({
        email: userObj["email"],
        uid: userObj["uid"],
        token: token,
      });

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.checkRole(userObj);
      this.getEvaluatorRoles();
      this.load();
    }
  }

  render() {
    return (
      <Container fluid style={{ minHeight: "100vh", padding: "0 50px 0 50px" }}>
        {this.state.signedInUser ? (
          <div>
            <NavBar></NavBar>

            {this.state.inAllowList ? (
              <Container fluid className="mt-5">
                {!this.userIsAdmin() && !this.evaluatorHasAssignment() && (
                  <Assignments
                    onClick={this.assignRole}
                    roles={this.state.roles}
                  ></Assignments>
                )}

                {this.userIsAdmin() && this.state.evals && (
                  <div>
                    <div>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            this.state.apps &&
                            this.state.evals && (
                              <div>
                                {!this.state.apps && (
                                  <h2>Failed to retrieve Applications</h2>
                                )}
                              </div>
                            )
                          }
                        ></Route>
                        <Route
                          path="/apps/:id"
                          element={
                            <ApplicationDetail
                              apps={this.state.apps}
                              user={this.state.uid}
                              evaluations={this.state.evals}
                              postSubmission={this.getEvals}
                            ></ApplicationDetail>
                          }
                        ></Route>
                        {/* <Route
                          path="/evals/viewevaluation/:id"
                          element={<EvaluationView></EvaluationView>}
                        ></Route> */}
                        <Route path="/stats" element={<Stats></Stats>}></Route>
                        <Route
                          path="/report"
                          element={
                            <Report
                              apps={this.state.apps}
                              evals={this.state.evals}
                            ></Report>
                          }
                        ></Route>
                        <Route
                          path="/apps"
                          element={
                            <AppTable
                              apps={this.state.apps}
                              roleNumber={0}
                              evaluations={this.state.evals}
                              user={this.state.user}
                              userID={this.state.uid}
                            ></AppTable>
                          }
                        ></Route>
                        <Route
                          path="/settings"
                          element={<Settings></Settings>}
                        ></Route>
                        <Route path="/awardees" element={<Awardees></Awardees>}>

                        </Route>
                        <Route
                          path="/evals"
                          element={
                            <EvaluationList
                              evals={this.state.evals}
                              roles={this.state.roles}
                              apps={this.state.apps}
                              users={this.state.users}
                            ></EvaluationList>
                          }
                        ></Route>
                        <Route path="evals/viewevaluation/:id" element={<EvaluationView evals={this.state.evals}></EvaluationView>}></Route>
                      </Routes>
                    </div>
                  </div>
                )}
                {this.evaluatorHasAssignment() && (
                  <div>
                    <h2 style={{ background: "lightgrey" }} className="p-2">
                      Review Applications
                    </h2>
                    <p>
                      Your evaluator role is: Evaluator{" "}
                      {this.getEvaluatorAssignment() + 1}
                    </p>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          this.state.apps &&
                          this.state.evals && (
                            <AppTable
                              apps={this.state.apps}
                              roleNumber={this.getEvaluatorAssignment()}
                              evaluations={this.state.evals}
                              user={this.state.uid}
                            ></AppTable>
                          )
                        }
                      ></Route>
                      <Route
                        path="/:id"
                        element={
                          <ApplicationDetail
                            apps={this.state.apps}
                            user={this.state.uid}
                            evaluations={this.state.evals}
                            postSubmission={this.getEvals}
                          ></ApplicationDetail>
                        }
                      ></Route>
                    </Routes>
                  </div>
                )}
              </Container>
            ) : (
              <h3>
                Your account is awaiting approval. Please check back later.
              </h3>
            )}
          </div>
        ) : (
          <div>
            {
              <Button
                variant="link"
                onClick={() =>
                  this.setState({ register: !this.state.register })
                }
              >
                {this.state.register ? (
                  <span>Login</span>
                ) : (
                  <span>Register</span>
                )}
              </Button>
            }
            <Container fluid className="mt-5">
              {this.state.register ? (
                <Register
                  onChange={this.handleChange}
                  signIn={this.signIn}
                  {...this.state}
                ></Register>
              ) : (
                <SignIn
                  onChange={{
                    handleChange: this.handleChange,
                    signIn: this.signIn,
                  }}
                  {...this.state}
                ></SignIn>
              )}
            </Container>
          </div>
        )}
      </Container>
    );
  }
}
