//This page shows a confirmation of all the data entered into the form and asks the applicant to accept the terms of the application
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Col,
  Row,
  Button,
  Table
} from "react-bootstrap";

export default class Page5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      scholars: [],
      edgeHistories: [],
      selectedScholarIndex: 0,
      selectedScholars: [],
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.redirect) {
      return <Link to={this.state.redirect} />;
    }
    return (
      <Row className="justify-content-center">
        <Col md={6}>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <h6 className="font-weight-bold">Review Submission</h6>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Table bordered striped>
                  <tbody>
                    <tr className="my-5">
                      <td><b>Page 1</b></td><td></td>
                    </tr>
                    <tr>
                      <td>First Name: </td>
                      <td> {this.props.firstName} </td>
                    </tr>
                    <tr>
                      <td>Middle Name: </td>
                      <td> {this.props.middleName}</td>
                    </tr>
                    <tr>
                      <td>Last Name: </td>
                      <td> {this.props.lastName} </td>
                    </tr>
                    <tr>
                      <td>Date of Birth: </td>
                      <td> {this.props.dob} </td>
                    </tr>
                    <tr>
                      <td>Email: </td>
                      <td>{this.props.email} </td>
                    </tr>
                    <tr>
                      <td>W-9 Eligible:</td>
                      <td>{this.props.w9 ? "Yes" : "No"}</td>
                    </tr>



                    <tr className="my-5">
                      <td><b >Page 2</b></td> <td></td>
                    </tr>
                    <tr>
                      <td>Race: </td>
                      <td>{this.props.race.join(",")}</td>
                    </tr>
                    <tr>
                      <td>Citizenship Status: </td>
                      <td>{this.props.citizenshipStatus} </td>
                    </tr>
                    <tr>
                      <td>Education Status: </td>
                      <td>{this.props.educationStatus} </td>
                    </tr>
                    <tr>
                      <td>College Credit Hours:</td>
                      <td>{this.props.creditHours}</td>
                    </tr>
                    <tr>
                      <td>GPA: </td>
                      <td>
                        {" "}
                        {this.props.gpa} / {this.props.gpaOutof}
                      </td>
                    </tr>



                    <tr className="my-5">
                      <td><b >Page 3</b></td>
                    </tr>
                    <tr>
                      <td>Expected Major(s)</td>
                      <td>{this.props.majors.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Essay: </td>
                      <td>{this.props.essay} </td>
                    </tr>
                    <tr>
                      <td>Transcript File(s):</td>
                      <td>{this.props.files.map((f) => f.metadata.name).join(", ")}</td>
                    </tr>


                    
                    <tr className="my-5">
                      <td><b >Page 4</b></td> <td></td>
                    </tr>
                    <tr>
                      <td>Awards: </td>
                      <td>{this.props.awards.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Projects: </td>
                      <td>{this.props.projects.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Extracurricular Activities: </td>
                      <td>{this.props.extracurriculars.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Leadership Roles: </td>
                      <td>{this.props.leadership.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Work: </td>
                      <td>{this.props.work.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Relevant Coursework: </td>
                      <td>{this.props.coursework.map(x => x.title).join(", ")}</td>
                    </tr>
                    <tr>
                      <td>Application Notes: </td>
                      <td>{this.props.notes} </td>
                    </tr>
               
                    
                  </tbody>
                </Table>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Check
                name="certify"
                label="I hereby agree to the terms herein and certify that the information provided herein is accurate and true."
                checked={this.props.certify}
                onClick={this.props.onChange.handleChange}
              ></Form.Check>
            </Form.Row>
            <Form.Row style={{ marginTop: "8em" }}>
              <Form.Group as={Col} md={8} className="text-left">
                <Button
                  variant="outline-primary"
                  onClick={this.props.firstStep}
                >
                  Edit Application
                </Button>
              </Form.Group>
              <Form.Group as={Col} md={4} className="text-right">
                <Button
                  variant="outline-primary"
                  onClick={this.props.nextStep}
                  disabled={!this.props.certify || this.props.submitting}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
