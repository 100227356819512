import React, { Component } from "react";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { APP_OPEN_DATE, DEADLINE } from "../constants";
import {
  Col,
  Row,
  Button,
} from "react-bootstrap";

export default class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appOpenDate: APP_OPEN_DATE,
      deadline: DEADLINE,
    }

    this.afterOpenDate = this.afterOpenDate.bind(this)
    this.beforeDeadline = this.beforeDeadline.bind(this)
    this.beforeOpenDate = this.beforeOpenDate.bind(this)
    this.afterDeadline = this.afterDeadline.bind(this)
  }

  afterOpenDate(){
    return Date.now() > this.state.appOpenDate
  }

  beforeDeadline(){
    return Date.now() < this.state.deadline
  }

  beforeOpenDate(){
    return Date.now() < this.state.appOpenDate
  }

  afterDeadline(){
    return Date.now() > this.state.deadline
  }


  render() {
    if(this.beforeDeadline() && this.afterOpenDate() ){
    return (
       <div>
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="about">About</h2>
            <p>
              The New England Statistical Society Scholarships for
              Underrepresented Minorities will advance the representation of
              underrepresented groups in statistics and data science by
              supporting graduating high school seniors and first/second-year
              undergraduate students interested in pursuing a career in a data science-related field (e.g., statistics, quantitative fields in social science, math, engineering, and computer science). The scholarships will
              support the efforts of NESS to improve the diversity and
              inclusivity of its community, as well as in the fields of
              statistics and data science.
            </p>

            <h5>Scholarship Amount</h5>
            <p>
The number of scholarships awarded and the monetary amount of scholarships will be decided annually based on available funding at the full discretion of the Scholarship Committee. The number of scholarships will be limited by the available funds for a given year, with a minimum target amount of $1,000 per person. 
            </p>
            <h5>Decision Schedule</h5>
            <p>Winners will be announced at the NESS Symposium at the end of May 2023, with winner packets distributed at the end of October (once registration is confirmed).
</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <h2>Eligibility</h2>
            <em>Required</em>
            <ul>
              <li>
                Graduating high school senior or first/second-year undergraduate
                student
              </li>
              <li>Black, Indigenous or Person of Color (BIPOC)</li>
              <li>Will be enrolled in a U.S. college or university for the upcoming 2023-2024 academic year.</li>
              <li>
              Shows a <strong><em>genuine</em></strong> interest in statistics and data science through coursework, extracurricular activities, and/or future goals.
              </li>
            </ul>
            <em>Considered</em>
            <ul><li>Those who are eligible for a Federal Pell Grant Program (for more information, please see the <a target="_blank" rel="noreferrer" href="https://www2.ed.gov/programs/fpg/eligibility.html">information page</a> from the U.S. Department of Education) will be given priority.</li></ul>

            <h4 style={{color: "red", border: "2px solid", padding: "10px"}}>Application Deadline: {format(new Date(this.state.deadline),' MMMM dd, yyyy')}</h4>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <h2>Application Sections</h2>
            <ol>
              <li>Personal Information</li>
              <li>Essay -  Please explain why you are interested in studying the major(s)
                  you have selected. If you selected undecided please describe
                  your areas of possible academic interest. Make sure to
                  describe how your interests relate to data science, provide
                  examples of how you have demonstrated your interest so far,
                  and how you plan to pursue your interests in college.</li>
              <li>Additional Experiences</li>
            </ol>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <h4>Legal Disclaimer</h4>
            <p>
              Applicant hereby agrees that NESS may use all information
              disclosed herein for the purposes of selecting scholarship
              recipients. NESS agrees to 1) maintain the confidentiality of all
              information disclosed herein with the same degree of care as it
              maintains the confidentiality of its own confidential information,
              which in no event shall be less than a reasonable standard of
              care; 2) not disclose Applicant information to any third party;
              and 3) only use Applicant information for the purposes of
              scholarship selection. NESS will promptly destroy all information
              disclosed herein upon formally selecting scholarship recipients.
              NESS reserves the right, at its sole discretion, to select
              scholarship recipients.
            </p>
            <h4>Tax Disclaimer/Receipt of Funds</h4>
            <p>
            By applying, the applicant acknowledges that this scholarship is to be viewed as income. Prior to receiving funds, scholarship winners will be prompted to confirm enrollment and fill out a w9 form in early October 2023. The address used on the W-9 form will be where winner packets are sent shortly after. NESS will provide the appropriate 1099 tax form prior to the tax deadline in April 2024. Applicants must ensure that they have access to, and regularly check, the email address used when submitting their application through April 2024 for any scholarship-related communication regarding the receipt of funds or otherwise.
            </p>
            <Button className="mt-5" variant="outline-primary" onClick={this.props.nextStep}>
              Start Application
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5" style={{height: "200px"}}>
          <Col md={6}>
            
          </Col>
        </Row>
      </div>
    );}
    else if(this.afterDeadline()){
      return(
        <div>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
          The application deadline for the 2024 NextGen Scholarship has passed.
            Please check again in October for news on next year's scholarship.
          </Col>
        </Row>
      </div>
      )
    }
    else if(this.beforeOpenDate()){
      return (
        <div>
          <Row className="justify-content-center mt-5">
            <Col md={6}>
            The application portal will open on {format(this.state.appOpenDate,'MM/dd/yyyy')}. Please visit the main <a href="https://nestat.org">NESS Website</a> for more information on Data Science Day. 
            </Col>
          </Row>
        </div>
      )
    }
    
  }
}
