import "./rubric.css";

import { Form, Table } from "react-bootstrap";

import React from "react";
import _ from "lodash"

export default function Rubric(props) {


  function isCellSelected(){

  }

  return (
    <div style={{margin: "100px auto"}}>
      <h4>{props.name}</h4>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Criteria</th>
             {
              props.labels.map((l,index) => (
                <th key={l}>{l} ({props.min + index})</th>
              ))
            }
            <th>Notes</th>
           
          </tr>
        </thead>
        <tbody>
          {props.elements.map((e) => (
            <tr key={e.Name} className="main-row">
              <td style={{ background: "white", fontWeight: "300", width: "150px" }}>
                {e.Name}
              </td>
              {e.Text.map((t, index) => (
                <td
                  className="rubric-cell"
                  value={index + props.min}
                  key={t}
                  onClick={() => {
                    props.onChange(e.Name, index + props.min);
                  }}
                  name={e.name}
                  style={{ background: e.Value === index + props.min && "#CCF" }}
                >
                  <div>{t}</div>
                </td>
              ))}
              <td className="note-cell">
                <Form.Control name={e.Name} value={e.Note} as="textarea" style={{position:"absolute", height: "80%", width:"95%"}} onChange={props.onNotesChange}></Form.Control>
              </td>
            </tr>
          ))}
          {props.showTotal && <tr>
            <td>Total</td>
            <td>{_.sum(props.elements.map(e => e.Value))} out of {props.elements.length * 4}</td>
          </tr>}
        </tbody>
      </Table>
    </div>
  );
}
