import React, { Component } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import "./essay.css";
import List from "./list.component";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
export default class Essay extends Component {
  constructor(props) {
    super(props);

    this.wordCount = this.wordCount.bind(this);
    this.charCount = this.charCount.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onKey = this.onKey.bind(this);
    this.ReactTags = React.createRef();
    this.state = {
      fileUploadProcessing: null,
      majors: [
        "Accounting",
        "Animal Science",
        "Anthropology",
        "Architecture",
        "Astronomy",
        "Biochemistry",
        "Biological Sciences",
        "Biology",
        "Biomedical Engineering",
        "Business",
        "Business Administration",
        "Chemistry",
        "Cognitive Science",
        "Communications",
        "Computer Engineering",
        "Computer and Information Science",
        "Computer Science",
        "Criminal Justice",
        "Criminology",
        "Economics",
        "Education",
        "Engineering",
        "English",
        "Film",
        "Finance",
        "Geography",
        "Geology",
        "Health Science",
        "History",
        "Human Biology",
        "Human Resources",
        "Human Services",
        "International Business",
        "International Relations",
        "International Studies",
        "Journalism",
        "Linguistics",
        "Management Information Systems and Services",
        "Marketing",
        "Mathematics",
        "Mechanical Engineering",
        "Music",
        "Nursing",
        "Nutrition",
        "Pharmacy",
        "Philosophy",
        "Physics",
        "Physiology",
        "Political Science",
        "Psychology",
        "Public Health",
        "Public Policy",
        "Real Estate",
        "Social Work",
        "Sociology",
        "Statistics",
        "Zoology",
      ],
      uconnMajors: [
        "Agriculture and Natural Resources",
        "Allied Health Sciences",
        "Animal Science",
        "Applied and Resource Economics",
        "Diagnostic Genetic Sciences",
        "Dietetics",
        "Environmental Sciences",
        "Environmental Studies",
        "Exercise Science",
        "Individualized Major",
        "Landscape Architecture",
        "Medical Laboratory Sciences",
        "Natural Resources",
        "Nutritional Sciences",
        "Pathobiology",
        "Sustainable Plant and Soil Systems",
        "Accounting",
        "Business Administration",
        "Business Data Analytics",
        "Finance",
        "Financial Management",
        "Health Care Management",
        "Management",
        "Management Information Systems",
        "Marketing",
        "Marketing Management",
        "Real Estate/Urban Economics",
        "Biomedical Engineering",
        "Chemical Engineering",
        "Civil Engineering",
        "Computer Engineering",
        "Computer Science",
        "Computer Science and Engineering",
        "Electrical Engineering",
        "Engineering Physics",
        "Environmental Engineering",
        "Management and Engineering for Manufacturing",
        "Materials Science and Engineering",
        "Mechanical Engineering",
        "Acting",
        "Art",
        "Art History",
        "Design and Technical Theatre",
        "Digital Media and Design",
        "General Program in Music",
        "Music",
        "Puppetry",
        "Theatre Studies",
        "Africana Studies",
        "American Sign Language Studies",
        "American Studies",
        "Anthropology",
        "Applied Mathematical Sciences",
        "Arabic and Islamic Civilizations",
        "Biological Sciences",
        "Chemistry",
        "Chinese",
        "Classics and Ancient Mediterranean Studies",
        "Cognitive Science",
        "Communication",
        "Ecology and Evolutionary Biology",
        "Economics",
        "Engineering Physics",
        "English",
        "Environmental Sciences",
        "Environmental Studies",
        "French",
        "Geography",
        "Geographic Information Science",
        "Geoscience",
        "German",
        "History",
        "Human Development and Family Sciences",
        "Human Rights",
        "Italian Literary and Cultural Studies",
        "Journalism",
        "Judaic Studies",
        "Latino and Latin American Studies",
        "Linguistics/Philosophy",
        "Linguistics/Psychology",
        "Marine Sciences",
        "Maritime Studies",
        "Mathematics",
        "Mathematics/Actuarial Science",
        "Mathematics/Actuarial Science/Finance",
        "Mathematics/Physics",
        "Mathematics/Statistics",
        "Molecular and Cell Biology",
        "Philosophy",
        "Physics",
        "Physiology and Neurobiology",
        "Political Science",
        "Psychological Sciences",
        "Sociology",
        "Spanish",
        "Speech, Language, and Hearing Sciences",
        "Statistics",
        "Structural Biology and Biophysics",
        "Urban and Community Studies",
        "Women's, Gender, and Sexuality Studies",
        "Biology Education",
        "Chemistry Education",
        "Earth Science Education",
        "Elementary Education",
        "English Education",
        "French Language Education",
        "General Science Education",
        "German Language Education",
        "History and Social Studies Education",
        "Mandarin Chinese Language Education",
        "Mathematics Education",
        "Music Education",
        "Physics Education",
        "Spanish Language Education",
        "Special Education",
        "Sport Management",
        "Nursing",
        "Pharmacy",
        "Social Work",
        "Data Science",
        "Other",
      ],
    };
  }

  wordCount() {
    return this.props.values.essay.split(/\s+\b/).length || 0;
  }
  charCount() {
    return this.props.values.essay.length;
  }

  onKey(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      const { selectionStart, selectionEnd } = event.target;
    }
  }

  // specify upload params and url for your files
  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status) => {
    this.setState({ fileUploadProcessing: true });
    console.log(status, meta, file);
    if (status === "done") {
      this.props.values.files.push({ imageFile: file, metadata: meta });
      this.setState({ fileUploadProcessing: false });
    }
    if (status === "removed") {
      var record = this.props.values.files.find((x) => x.imageFile === file);
      this.props.values.files.splice(
        this.props.values.files.indexOf(record),
        1
      );
      this.setState({ fileUploadProcessing: false });
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  render() {
    return (
      <Row className="justify-content-center ">
        <Col md={6}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Row>
              <Form.Group>
                <h6 className="font-weight-bold">
                  Current or expected College Major (Choose all the apply)
                  <em style={{ color: "red" }}>&#42;</em>
                </h6>
              </Form.Group>
            </Form.Row>
            <Typeahead
              id="typeahead"
              multiple
              onChange={(selected) => {
                this.props.onChange.onTypeaheadChange(selected);
              }}
              options={this.state.uconnMajors.sort()}
              className="mb-5"
              selected={this.props.values.majors}
            ></Typeahead>
            {this.props.values.majors.includes("Other") && (
              <List
                name="majors"
                value={this.props.values.majors}
                onChange={this.props.onChange.listChange}
                noListRender
              >
                Add a major not in the list above
              </List>
            )}
            <div className="text-danger">
              {this.props.validator.message(
                "Major",
                this.props.values.majors,
                "required|alpha_space"
              )}
            </div>
            <Form.Row>
              <Form.Group as={Col}>
                <h6 className="font-weight-bold mt-5">
                  Essay<em style={{ color: "red" }}>&#42;</em>
                </h6>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formEssay" as={Col}>
                <Form.Label>
                  Please enter a short essay, 500 words maximum
                </Form.Label>
                <p>
                  Please explain why you are interested in studying the major(s)
                  you have selected. If you selected undecided please describe
                  your areas of possible academic interest. Make sure to
                  describe how your interests relate to data science, provide
                  examples of how you have demonstrated your interest so far,
                  and how you plan to pursue your interests in college.
                </p>
                <em>
                  Use your response as an opportunity to tell a story about how
                  your major connects with your career goals. What experiences
                  or high school courses piqued your interest?{" "}
                </em>
                <Form.Control
                  as="textarea"
                  rows={20}
                  required
                  name="essay"
                  placeholder="Write your message here. We recommend copying from notebook to avoid loss"
                  aria-label="Write your message here. We recommend copying from notebook to avoid loss"
                  aria-describedby="Write your message here. We recommend copying from notebook to avoid loss"
                  onChange={this.props.onChange.handleChange}
                  value={this.props.values.essay}
                />
                <div id="word-counter">
                  <span
                    id="word-count"
                    className={
                      this.wordCount() > 500 ? "word-count-over" : "word-count"
                    }
                  >
                    {this.wordCount()}/500
                  </span>
                </div>
                {/* <div id="word-counter"><span id="word-count"  className={this.charCount > 550 ? 'word-count-over' : 'word-count' }>{this.charCount()}/500</span></div> */}
                <div className="text-danger">
                  {this.props.validator.message(
                    "Essay",
                    this.props.values.essay,
                    "required"
                  )}
                </div>
                {(this.wordCount() > 500 || this.charCount() > 3500) && (
                  <div className="text-danger">Essay over maximum length!</div>
                )}
                  {(this.wordCount() < 250) && (
                  <div className="text-danger">Essay must be at least 250 words.</div>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <h6 className="font-weight-bold">
                  Unofficial Transcript<em style={{ color: "red" }}>&#42;</em>
                </h6>
                <p className="mt-0">
                  Accepted file formats: .png, .jpeg, .pdf{" "}
                  <i>(Each file must be 5MB or less)</i>
                </p>
                <Dropzone
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  onSubmit={this.handleSubmit}
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  maxFiles={5}
                  maxSizeBytes={5000000}
                />
                {this.props.values.files && (
                  <div>
                    <h5 className="my-5">Files Uploaded:</h5>
                    <ul>
                      {this.props.values.files.map((f) => {
                        return (
                          <li key={f} className="uploaded-file-name">
                            {f.metadata.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row style={{ marginTop: "1em" }}>
              <Form.Group as={Col} className="text-left">
                <Button variant="outline-primary" onClick={this.props.prevStep}>
                  Prev
                </Button>
              </Form.Group>
              <Form.Group as={Col} className="text-right">
                <Button
                  className="next-btn"
                  variant="outline-primary"
                  onClick={this.props.nextStep}
                  disabled={
                    !this.props.testing.testing &&
                   ( this.wordCount() > 550 ||
                    this.charCount() > 3500 ||
                    this.wordCount() < 250 ||
                    this.state.fileUploadProcessing ||
                    this.props.values.files.length === 0) 
                  }
                >
                  Next
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
