import React from "react";
import { Table } from "react-bootstrap";
import Evaluation from "./evaluation.component";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function EvaluationView(props) {
  let params = useParams()
  const [evalulation, setEvalulation] = useState( props.evals.find((a) => a._id === params.id))
  return (
    <div>
   
      <Evaluation
        // student={application._id}
        // user={props.user}
        eval={props.eval}
        // postSubmit={props.postSubmission}
      ></Evaluation>
    </div>
  );
}
